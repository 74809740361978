.container-all-center{
    display: grid;
    justify-content: center;
    align-content: center !important;
    width: 100vw;
    height: calc(100vh - 75px);
    background: #F5F5F5;
}

.align-button{
    display: grid;
    justify-content: center;
    margin-top: 20px;
}