.product-showcase{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 5vw;
    box-shadow: var(--shadow);
    min-height: calc(100vh - 75px);
    background: linear-gradient(-45deg, #bcd7f3, #d9e2ee, #cde2ef);
    background-size: 400% 400%;
    animation: gradient 8s ease infinite;
}

.product-details-container{
    background: #F7F7F7;
    padding: 5vw;
    display: grid;
}

.product-image {
    grid-column: 2; /* Move the product-image to the second column */
}

.product-description {
    grid-column: 1; /* Move the product-description to the first column */
}

.product-details{
    background: #FFF;
    height: 100%;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    justify-content: center;
}

.product-image{
    min-width: 30%;
    max-width: 400px;
    display: flex;
    justify-content: center;
}

.product-description {
    max-width: calc(100% - 40%);
    display: block;
    font-size: 1.2em;
    padding-top: 100px;
}

.reverse{
    flex-direction: row-reverse; /* Inverte a ordem dos elementos */
}

@media screen and (max-width: 1425px) {
    .product-details{
        gap: 5vw;
    }
}

@media screen and (max-width: 870px) {
    .product-description {
        max-width: 100%;
    }

    .product-details{
        gap: 0;
    }

    .product-image{
        margin: 0 auto;
    }
}


.glass{
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-evenly;
    border-radius: 10px;
    width: 100%;
    gap: 30px;

/*    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5vw;*/

}

.switch-card{
    cursor: pointer;
    padding: 20px;
    outline: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background: var(--white);

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.minimalist-card{
    cursor: pointer;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    max-width: 400px;
    font-size: 1.2em;
}

.minimalist-card-content{
    height: 500px;
}

.minimalist-card img{
    margin: 0 auto;
}

.minimalist-card div{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.bi-plus-circle-fill{
    font-size: 1.6em;
    cursor: pointer;
}

.product-showcase-accordion{
    display: grid;
    gap: 30px;
}

.showcase-label{
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.showcase-label div{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    color: var(--primary-color);
    font-size: 1.2rem;
    border: solid 1px var(--primary-color);
    background: transparent;
}

.bi-plus-circle-fill:hover{
    opacity: 0.8;
    transition: 0.3s;
}

.rotated {
    transform: rotate(45deg);
}

.bi-plus-circle-fill{
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-block{
    display: grid;
}

.commercial *{
    color: white;
}

.product-list{
    display: flex;
    flex-wrap: wrap;
    gap: 5em;
    margin-top: 5em;
}
.product-list ul{
    max-width: 450px;
}

.benefits-section{
    padding: 5vw;
    display: grid;
    align-items: center;

}

.table-wrapper {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 5em;
}

.striped-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.striped-table thead tr {
    background-color: #F3F3F3;
}

.striped-table thead td {
    padding: 10px;
}

.striped-table tbody tr:nth-child(even) {
    background-color: #F3F3F3;
}

.striped-table tbody td {
    padding: 10px;
}

.bi.bi-check {
    color: var(--primary-color);
    font-size: 2em;
}

.animation{}