*{
    font-family: var(--font);
}

main {
    min-height: 100vh;
    padding: 150px 15vw 90px;
}

@media only screen and (max-width: 500px){

    main {
        padding: 80px 20vw;
    }

    .custom-card{
        padding: 10px !important;
        width: 75vw;
    }
}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track {
    background: var(--secondary-background);
}

body::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

.sidebar-fixed {
    position: fixed !important;
    background: var(--primary-background);
}

.sidebar-fixed * {
    color: var(--secondary-background) !important;
}

label {
    margin: 5px 0 !important;
}

.card-container {
    display: grid;
    gap: 60px;
}

.short-button {
    max-width: 250px;
    min-width: 200px;
    height: 50px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 50px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 0.5s;
}

.language-button{
    width: 50px;
    height: 50px;
    background-color: var(--primary-color);
    border: none;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 0.5s;
    margin-top: 6px;
    text-transform: uppercase;
}

#basic-nav-dropdown{
    color: var(--white) !important;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0;
    content: "";
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

.short-button:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: all 0.5s;
}

.short-button:hover .bi{
    animation: rotate 1s infinite linear;
    transition: all 0.5s;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(30deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-30deg);
        left: 30px;
    }
    100% {
        transform: rotate(0deg);
        left: 30px;
    }
}

.bi {
    font-size: 1.4em;
}

.remove-link-style{
    text-decoration: none !important;
    color: var(--secondary-background);
}

.responsive-image{
    max-width: 100%;
}

.desktop-image{
    display: block;
}

.mobile-image{
    display: none;
}

.desktop-image, .mobile-image{
    margin: 30px 0;
}

@media only screen and (max-width: 1100px){
    .mobile-image{
        display: block;
        max-width: 400px;
        width: 100%;
    }

    .desktop-image{
        display: none;
    }
}

.api-section{
    max-width: 70vw !important;
}

.bolder{
    font-weight: bolder;
    color: var(--primary-color);
}

.select-language{
    display: flex;
    justify-content: right;
    padding: 20px 30px;
    box-shadow: rgba(0, 0, 0, 0.09) 0 3px 12px;
    position: fixed;
    width: 100vw;
    z-index: 10;
    background: var(--white);
}

.emphasis{
    font-size: 1.5em;
    font-weight: lighter;
}

.navbar>.container{
    background: var(--white);
}

.navbar-brand{
    margin-left: 5vw;
}

.navbar-toggler-icon {
    margin-right: 5vw !important;
}

.card-text{
    text-align: left;
}

.nav-link{
    display: flex;
    align-items: center;
}


.min-vh-100{
    padding: 5vw 20px;
    box-sizing: border-box;
}

.center-grid{
    display: grid;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.silver{
    color: var(--font-secondary-color);
}

.phrase{
    font-size: 1.2em;
    max-width: 500px;
}

.pattern{
    background: linear-gradient(-45deg, #0066CC, #0055CC, #0068d0, #0055CC);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
    color: white;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

a{
    text-decoration: none;
    color: var(--font-primary-color);
}

a:hover{
    color: var(--font-secondary-color);
    transition: all 0.3s;
}
