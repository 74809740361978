.card-large{
    background: var(--primary-color);
    color: var(--white);
    padding: 30px;
    border-radius: 10px;
    width: calc(50vw - 30px);
    height: calc(100vh - 40px);
}

.card-medium{
    background: var(--white);
    color: var(--font-primary-color);
    padding: 30px;
    border-radius: 10px;

    width: calc(50vw - 30px);
    height: calc(100vh - 40px);
}