.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: calc(100vh - 75px);
    background: #F5F5F5;
}

.slide-text {
    display: grid;
    align-items: center;
    text-align: start;
    gap: 20px;
    padding: 40px 5vw;
    width: 60vw;
    height: 100%;
}

.slide-text h1 {
    max-width: 700px;
}

.slide-image {
    width: 40vw;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.slide-image::before {
    content: '';
    display: block;
    padding-top: 75%;
}

.slide-image img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .each-slide-effect > div {
        flex-direction: column-reverse;
    }

    .slide-image, .slide-text {
        width: 100vw;
    }
}