footer {
    padding: 100px 5vw 30px;
    background: var(--code-theme);
}

footer * {
    list-style: none;
    padding: 0;
}

footer ul li:first-child {
    font-size: 1.6em;
    margin-bottom: 20px;
}

.footer-list {
    display: flex;
    gap: 5vw;
    flex-wrap: wrap;
}

.footer-list ul{
    min-width: 180px;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.bi-linkedin {
    font-size: 2em;
    gap: 20px;
    color: var(--primary-color);
    cursor: pointer;
}

.footer-contact{
    display: grid;
    justify-content: center;
    text-align: center;
    gap: 30px;
    margin-bottom: 100px;
}

.footer-contact p{
    font-size: 1.5em;
}
