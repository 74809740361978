.post{
    border-left: solid 6px var(--primary-color);
}

.badge{
    font-style: normal;
    background: var(--secondary-background);
    color: var(--http-post);
    padding: 10px;
    font-weight: normal;
    border-radius: 3px;
    font-size: 0.8em;
    text-transform: uppercase;
    margin-left: 10px;
}

.badge-put{
    color: var(--http-put);
}

.badge-post{
    color: var(--http-post);
}

.badge-get{
    color: var(--http-get);
}

.badge-delete{
    color: var(--http-delete);
}

.response-code{
    display: flex;
    gap: 15px;
    align-items: center;
}