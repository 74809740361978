.intro-container {

}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: none !important;
}

.navbar {
    background: var(--white) !important;
    height: 75px;
    box-sizing: border-box;
}

.intro-container {
    background: rgb(255, 0, 131);
    background: radial-gradient(circle, #FE2A8E 0%, #FF7FBC 100%);
    min-height: calc(100vh - 75px);
    display: flex;
    justify-content: center;
    gap: 5vw;
    flex-wrap: wrap;
    padding: 30px;
    align-items: center;
}

.dropdown-item,
.dropdown-item:focus,
.dropdown-item:active,
.dropdown-item:focus {
    background: var(--white);
}

.logo-horizontal {
    max-width: 130px;
}

.navbar-items {
    justify-content: right;
    display: flex;
    gap: 2vw;
    width: 100%;
    color: var(--font-primary-color) !important;
}

@media only screen and (max-width: 991px) {
    .navbar-items {
        padding-bottom: 20px;
    }
}

.dropdown-menu {
    border: none !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.yellow-button {
    min-width: 150px;
    border: none;
    border-radius: 20px;
    background: var(--accent-color);
    color: var(--font-primary-color);
    font-weight: bolder;
    transition: all 0.5s;
    height: 40px;
}

.yellow-button:hover {
    background-color: var(--accent-alternative-color);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: all 0.5s;
}

.primary-form {
    display: grid;
    height: fit-content;
    padding: 40px;
    border-radius: 10px;
    gap: 20px;
    box-shadow: var(--shadow);
    background: var(--white);
}

.primary-form label {
    text-align: center;
    font-weight: bolder;
}


.primary-input {
    border-radius: 10px;
    border: solid 1px var(--silver);
    height: 40px;
    padding: 15px;
}


.thin-white {
    font-weight: normal;
    color: var(--white);
}

.basic-grid {
    display: grid;
    max-width: 400px;
}

.grid-pattern {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 10vw;
    min-height: 60vh;
    align-items: center;
    justify-content: space-between;
}

.grid-closer {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 5vw;
    gap: 30px;
    min-height: 60vh;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .grid-pattern {
        padding: 20px;
    }
}


.text-container {
    max-width: 400px;
    display: grid;
    gap: 60px;
}

.home-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    margin-bottom: 20px;
}

.home-cards {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 30px;
    flex: 1 1 300px; /* Defina um tamanho base para os cards */
    max-width: 400px; /* Defina um tamanho máximo para evitar que os cards fiquem muito largos */
    min-width: 250px; /* Defina um tamanho mínimo para evitar que os cards fiquem muito estreitos */
    text-align: left;
}

.home-cards * {
    text-align: left;
}

.section-how-it-works {
    display: grid;
    justify-content: center;
    background: var(--ice);
}

.secondary-button {
    background: linear-gradient(131.75deg, #FD258A 25.59%, #FFD6E9 120.61%);
    border-radius: 8px;
    color: var(--white);
    border: none;
    padding: 0 40px;
    height: 50px;
    box-sizing: border-box;
}

.align-center {
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
    display: flex;
}

.advantage-section-title, .advantage-section-title p {
    display: block;
}

.advantage-section-title p {
    text-align: left;
    margin: 0;
    padding: 0;
    color: var(--primary-color);
    font-size: 1.3em;
}

.title-header {
    font-weight: bolder;
    font-size: 2em;
}

.main-advantages-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px 0;
    width: 100% !important;
}


.advantage-card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 10px;
    padding: 20px;
    display: grid;
}

.advantage-card p {
    text-align: left;
}

#advantage-card-a {
    max-width: 400px;
    align-items: center;
    display: grid;
}

#advantage-card-b {
    background: var(--primary-background);
    color: var(--white);
    max-width: 400px;
    align-items: center;
    display: grid;
}

#advantage-card-c {
    background: linear-gradient(180deg, #0055AA 0%, #0066CC 100%);
    color: var(--white);
    align-items: center;
    display: grid;
    border-bottom-right-radius: 100px;
}

.advantage-card-section {
    display: grid;
    gap: 20px;
    text-align: left !important;
}

.advantage-card-section-flex {
    display: flex;
    gap: 20px;
}

.card-icon {
    max-width: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.card-icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(10px);
    z-index: -1;
}

.percentage-indicator {
    font-size: 3em;
    font-weight: 900;
    margin: 0;
    color: #3A95EF;
}


@media only screen and (max-width: 1443px) {
    .right-mobile {
        display: flex;
        justify-content: right;
        width: 100%;
    }

    .image-right-mobile {
        margin: 0;
    }
}

@media only screen and (max-width: 1443px) {
    .right-mobile {
        display: flex;
        justify-content: right;
        width: 100%;
    }

    .image-right-mobile {
        margin: 0;
    }
}


@media only screen and (max-width: 390px) {
    .advantage-card-section-flex {
        flex-wrap: wrap;
    }

    .grid-pattern {
        padding: 0;
    }
}


.solution_card {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 30px 20px;
    min-width: 320px;
    justify-content: space-between;
}

p {
    align-items: baseline;
    margin: 0 !important;
}

.fake-radio-button {
    background: var(--code-theme);
    width: 50px;
    height: 30px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    box-shadow: inset 0px 0.84703px 1.12937px rgba(0, 0, 0, 0.25);
    justify-content: flex-start;
    cursor: pointer;
}

.radio-button-ball {
    background: var(--white);
    width: 20px;
    height: 20px;
    border-radius: 40px;
    box-shadow: 0px 0px 2.82343px rgba(0, 0, 0, 0.2);
    transition: transform 0.8s; /* Adicionamos transição para a propriedade transform */
}

.fake-radio-button:hover .radio-button-ball {
    background: var(--primary-color-hover);
    transform: translateX(20px); /* Definimos a transição de movimento para a direita */
}

.underscore {
    color: var(--primary-color);
}

.solution-cards-display {
    display: grid;
    gap: 15px;
    justify-content: center;
    width: 100%;
    max-width: 30vw;

}

.limit-image {
    max-width: 45vw;
}

@media only screen and (max-width: 800px) {
    .solution-cards-display {
        max-width: 100vw;
        order: 1;
    }

    .limit-image {
        max-width: 100vw;
        order: 2;
        margin: 0 auto;
    }
}

.solution-title {
    text-transform: uppercase;
}

.new-solution-flag {
    text-transform: none;
    color: var(--sea-green);
}

.main-circle {
    animation: rotate 5s linear infinite;
}

.plans-container {
    display: grid;
    background: var(--soft-black);
    padding: 30px 5vw;
    min-height: 60vh;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: center;
}

.vertical-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

#text-free-trial {
    color: var(--accent-color);
    font-size: 1em;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
}

#text-plans {
    color: var(--white);
    font-size: 1.8em;
    font-weight: bolder;
}

#text-about {
    color: var(--white);
    font-size: 1.2em;
    font-weight: normal;
    margin-bottom: 30px;
}

.plans-cards-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.plan-card {
    max-width: 400px;
    border: none;
    background: var(--font-secondary-color) !important;
    color: var(--white);
    text-align: left;
    border: none !important;
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.09) 0 3px 12px;
}

.plan-card .card-title {
    font-size: 1.6em;
    font-weight: bolder;
}

.round-square {
    border-radius: 8px;
    width: 100%;
}

.card-image {
    width: 100%;
    height: 100px;
    border-radius: 10px 10px 0 0;
    background: var(--primary-background);
}

.check-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 15px;
}

.check-list li {
    display: flex;
    gap: 15px;
    align-items: baseline;
}

.bi-check-lg {
    color: var(--white);
}

.checkIcon {
    min-width: 28px !important;
    height: 28px !important;
    background: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.adjust-card-gap {
    display: grid;
    gap: 25px;
}

.responsive-container {
    padding: 30px 5vw;
    box-sizing: border-box;
}

@media only screen and (max-width: 500px) {
    .container-mobile-only {
        padding: 30px 5vw;
    }
}

.grid-center {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
}

@media only screen and (max-width: 1285px) {
    .grid-center {
        display: grid;
        justify-content: center;
    }
}

.card-title-style {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 1.5em;
    margin-bottom: 20px;
}

#plan-image-a {
    background-image: url('../images/home/9e40a3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#plan-image-b {
    background-image: url('../images/home/4b43f5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#plan-image-c {
    background-image: url('../images/home/b0f629.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.item {
    min-height: 40rem;
}

.item img {
    width: 100%;
    border-radius: 2rem;
    pointer-events: none;
}

.inner-carrousel {
    display: flex !important;
    box-sizing: border-box;
    gap: 20px;
}

.carrousel {
    cursor: grab;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 30px;
}

.commercial {
    background: #131313;
    background: -webkit-linear-gradient(to right, #1f1f1f, #111111);
    background: linear-gradient(to right, #1f1f1f, #111111FF);
    display: grid;
    justify-content: center;
    min-height: 100vh;

}

.glass-effect {
    width: 350px;
    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 600px;
    color: var(--white);
}

.card-header {
    padding: 5%;
    background: rgba(0, 0, 0, 0.84);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: baseline;
    height: 120px;
}

.card-header img {
    width: 60px;
}

.card-body {
    padding: 5%;
    text-align: left;
    font-size: 1.2em;
    font-weight: normal;
    justify-content: space-between;
    flex-direction: column;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.commercial-header {
    display: grid;
    justify-content: center;
    text-align: center;
    padding-top: 60px;
}

.commercial-header p {
    color: var(--white);
    font-size: 1.8em;
    font-weight: bolder;
}

.commercial-header h4 {
    color: var(--silver);
    font-size: 1.2em;
    text-transform: uppercase;
}

.yellow-text {
    color: var(--accent-color);
}

.format-text {
    display: block;
    text-align: left;
}

/* Our solution section */
.our-solution {
    background: var(--code-theme);
    min-height: 100vh;
    border: solid 2px blue;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: white;
    z-index: 100;
    width: 100vw;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 0;
}

@media screen and (max-width: 990px) {
    .navbar-collapse{
        padding: 10px 5vw;
    }

    .navbar-nav .dropdown-menu {
        position: absolute;
    }
}

@media screen and (max-width: 991px){
    .container, .container-md, .container-sm {
        max-width: 100%;
        width: 100%;
    }

    .navbar-collapse {
        padding: 10px 5vw;
    }
}

ul{
    list-style: none;
    padding-left: 0;
}

.group-list{
    display: flex;
    flex-wrap: wrap;
    gap: 5vw;
}

.blue-bullet-list li{
    margin-bottom: 20px;
    max-width: 400px;
    display: flex;
    gap: 20px;
    align-items: start;
}

.blue-bullet-list i{
    color: var(--primary-color);
}

.flex-two-col{
    display: flex;
    justify-content: space-evenly;
    text-align: start;
    align-items: center;
    gap: 5vw;
}

@media screen and (max-width: 550px) {
    .flex-two-col{
        display: grid;
        gap: 5vw;
        height: fit-content;
    }
}

.align-vertical{
    max-width: 900px;
    display: grid;
    justify-content: space-between;
    gap: 40px;
}

.image-side{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
