/* GRAPHIC */

.graphic {
    background: white;
    width: fit-content;
    max-width: 100%;
    padding: 20px;
    display: block;
    text-align: center;
    box-shadow: var(--shadow);
    border-radius: 10px;
    color: var(--font-secondary-color);
}

.bar-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    height: 350px;
    margin: 20px auto;
    background-image: linear-gradient(to right, #eeeeee 1px, transparent 1px),
    linear-gradient(to bottom, #eeeeee 1px, transparent 1px);
    background-size: 20px 20px;
    padding: 20px;
    box-sizing: border-box;
    font-weight: lighter;
}

.bar {
    width: 60px;
    background: linear-gradient(to bottom, var(--primary-color), #0671de);
    border-radius: 10px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 100;
    padding: 5px 0;
    transition: height 0.3s ease;
}

.bar-1 {
    height: 30%;
}

.bar-2 {
    height: 40%;
}

.bar-3 {
    height: 60%;
}

.bar-4 {
    height: 90%;
}

@media screen and (max-width: 500px) {
    .graphic,
    .bar-container {
        max-width: 100%;
    }

    .bar-container .bar-1 {
        display: none;
    }
}

/* GRAPHIC */

/* SECTION 01 */

.primary-container {
    background: #f7f7f7;
    min-height: 100vh;
    padding: 5vw;
    display: grid;
    align-items: center;
    box-sizing: border-box;
}

.primary-container h2{
    font-size: 3em !important;
    color: black;
    font-weight: bold;
}

/* SECTION 02 */

.brazilian-case{
    background: #fff;
    min-height: 100vh;
    display: flex;
    padding: 5vw;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    color: var(--font-secondary-color);
}

.brazilian-case-text{
    max-width: 600px;
    font-size: 1.2em;
    display: grid;
    gap: 30px;
}

.brazilian-case-text h2{
    font-size: 3em;
    color: black;
    font-weight: bold;
}

/* SECTION 03 */

.partners{
    min-height: 100vh;
    background-color: #ffffff;
    background-image: url("../images/about_us/partners.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    font-weight: bold;
}


/* HOLOGRAPHIC */

.holographic-effect {
    background: linear-gradient(45deg, #0066CC, #0680e3, #0055AA, #0680e3);
    background-size: 400% 400%;
    animation: hologramAnimation 3s infinite;
}

@keyframes hologramAnimation {
    0%, 100% {
        background-position: 0 50%;
    }
    25% {
        background-position: 100% 0;
    }
    50% {
        background-position: 0 50%;
    }
    75% {
        background-position: 100% 100%;
    }
}

/* CARDS */

.group-number-cards{
    display: flex;
    max-width: 600px;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
}

.card-large-primary {
    width: 100%;
    height: 300px;
    background: var(--primary-color);
    border-radius: 10px;
    padding: 20px;
    color: white;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-gap: 0;
}

.card-medium-secondary {
    width: 285px;
    height: 300px;
    /* From https://css.glass */
    background: #0F0F0F;
    border-radius: 10px;
    padding: 20px;
    color: white;
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-gap: 0;
}

.card-large-primary b, .card-medium-secondary b{
   font-size: 4rem;
}

@media screen and (max-width: 667px) {
    .card-medium-secondary {
        width: 100%
    }
}

@media screen and (max-width: 1333px) {
    .card-large-primary {
        margin-top: 30px;
    }

    .brazilian-case-text h2, .primary-container h2{
        font-size: 2em !important;
    }
}

.faq{
    display: grid;
    padding: 5vw;
    gap: 20px;
    justify-content: center;
    text-align: center;
}