.bg-img {
    width: 100%;
    height: 400px;
    background-image: url('../images/about_us/partners.png');
    background-size: cover;
    background-position: bottom;
    transition: filter 0.3s ease-out;
}

.medium-container{
    min-height: 100vh;
    display: grid;
}

.medium-content {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 60px 5vw;
}

.medium-content p {
    max-width: 600px;
    font-size: 1em;
    color: var(--font-secondary-color);
}