@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');

:root {
    --white: #ffffff;
    --code-theme: #f5f5f5;
    --font-code-theme: #0066CC;
    --primary-background: #212121;
    --black: #000;
    --soft-black: #333333;;
    --secondary-background: #f6f6f6;
    --ice: #F5F5F5;
    --silver: #e0e0e0;
    --silver-hover: #eee;
    --primary-color: #0066CC;
    --accent-color: #0066CC;
    --accent-alternative-color: #0066CC;
    --primary-color-hover: #ff88b2;
    --font: 'Poppins', sans-serif;
    --font-primary-color: #212121;
    --font-secondary-color: #757575;
    --font-tertiary-color: #bdbdbd;
    --shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
    --alert-param: #F9F4C8;
    --alert-param-font: #948910;
    --alert-endpoint: #D9F5FC;
    --alert-endpoint-font: #0B6C84;
    --alert-response: #E5F9C7;
    --alert-response-font: #A0AB0D;
    --http-post: #c99f05;
    --http-put: #1169c0;
    --http-get: #108f48;
    --http-delete: #af2c20;
    --sea-green: #00BA88;
}