@font-face {
    font-family: 'JetBrains Mono';
    src: url('fonts/jet_brains_mono/ttf/JetBrainsMono-Regular.ttf') format("truetype");
}

.custom-card{
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 30px;
    max-width: 80vw;
    min-width: 80vw;
}

@media only screen and (max-width: 500px) {
    .custom-card{
        min-width: 60vw;
    }
}

.alert{
    border: none;
}

.param{
    background: var(--alert-param);
    color: var(--alert-param-font);
}

.endpoint{
    background: var(--alert-endpoint);
    color: var(--alert-endpoint-font);
}

.response{
    background: var(--secondary-background);
    color: var(--font-primary-color);
    display: flex;
    gap: 20px;
    align-items: center;
}

.bi-check-circle-fill{
    color: var(--alert-response-font);
}

.postman-section{

    margin-top: 300px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.postman-section img{
    max-width: 30vw;
    min-width: 250px;
}

.postman-section-mobile-first{
    display: grid;
    gap: 30px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.param-table, th, td{
    border: none;
    border-collapse: collapse;
}

.param-table th{
    color: var(--font-secondary-color);
    font-weight: normal;
}

.param-table tr:nth-child(even) {
    background: var(--secondary-background);
}
.card-label{
    font-size: 0.9em;
    color: var(--font-secondary-color);
    padding-bottom: 10px;
}

.copy-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: var(--code-theme);
    border-radius: 5px;
    color: var(--font-primary-color);
    cursor: pointer;
    height: 50px;
}

.copy-input .bi{
    background: var(--code-theme);
    padding-left: 10px;
}

.invisible-input{
    border: none;
    display: flex;
    font-family: 'JetBrains Mono', monospace;
    overflow-x: auto; /* Adiciona um scroll horizontal quando necessário */
    white-space: nowrap; /* Impede a quebra de linha */
}

.endpoint-param {
    color: var(--font-code-theme);
}